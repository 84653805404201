<template>
  <div class="news-container">
    <div class="scroll-container">
        <div class="main-container">
            <div class="left">
                <div class="title-wrapper" :style="`background-image: url(${productDetail && productDetail.backgroundImg});`">
                <h2 class="title ellipsisTwo" :title="productDetail.title">{{productDetail && productDetail.title}}</h2>
                <p class="t-c">
                    <span :class="['save',{'active': isClocActive}]" @click="changeFollow">{{ clocText }}</span>
                    <span :class="['like',{'active': isLike}]" @click="changeLike" >{{ isLike ? '已点赞' : '点赞'}} {{likesNum}}</span>
                    <span class="read">阅读 {{productDetail && productDetail.readNum}}</span>
                    <span class="time">{{productDetail && productDetail.releaseTime}}</span>
                    <span v-show="productDetail && productDetail.realmName" class="tag">{{productDetail && productDetail.realmName}}</span>
                    <span v-show="productDetail && productDetail.productName" class="tag">{{productDetail && productDetail.productName}}</span>
                    <!-- <span class="tag" v-for="item of tagList" :key="item.id">{{item.name}}</span>productDetail -->
                </p>
                <button class="back" @click="goBack"></button>
                </div>
                <div class="content-wrapper">
                    <p>{{productDetail && productDetail.info}}</p>
                    <!-- <div v-html="{{productDetail && productDetail.info}}"></div> -->
                    <!-- <h3 class="section-title">
                        <span class="num">-</span>
                        力挺！中外新指南一致推荐二甲双胍用于稳定的慢性心衰患者
                    </h3> -->
                    <div v-html="productDetail && productDetail.content"></div>
                    <!-- <img style="width: 100%;" src="./images/n-1.png" alt=""> -->
                    <p class="audit-no" v-if="productDetail.auditNo">{{productDetail.auditNo}}</p>
                </div>
            </div>
            <div class="right">
                <recommended-reading :color="classBg" :productId="productId" :producType="producType" :productIds="productIds"></recommended-reading>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import indexApi from '@/api/index'
import RecommendedReading from "./recommended-reading";
export default {
  name: "news-details",
  components: {RecommendedReading},
  data() {
    return {
      title: '',
      tagList: [],
      time: '',
      detailID: '',
      productDetail: {},
      producType: '',
      classBg: '',

      isLike:false,
      likesNum : 0,

      clocText:'收藏',
      isClocActive:false,

      productId:''
    }

  },
  created() {
    this.detailID = this.$route.params.id;
    this.producType = parseInt(this.$route.query.producType);
    let productId = this.$route.query.productId;
    this.productIds = this.$route.query.productIds;
    this.productId = productId;
    console.log('this.class============',this.producType == '7', typeof this.producType)

    this.IgnoreSecurity = this.$route.query.IgnoreSecurity;

    if( !this.detailID ){
        this.$router.replace({name: 'login'})
        return;
    }

    switch (this.producType){
        case 7: //消化系统
        
          this.classBg = '#0F69AF'
          break;
        case 8: //心血管
          this.classBg = '#E61E50'
          break;
        case 9: //甲状腺
          this.classBg = '#2DBECD'
          break;
        case 10: //糖尿病
          this.classBg = '#EB3C96'
          break;
      }
      let type = this.$route.query.type;
      if(type == '1'){
        this.getProductDetail();
      } else {
        this.getProductDynamicDetail();
      }
      
      productId && sessionStorage.setItem('productId',productId)
    
  },
  mounted() {
    // this.getListData();
  },
  methods: {
    // /productDynamic/detail/{id}
    getListData() {
      this.title = '心衰患者降糖有顾忌？指南喊你放心用二甲双胍啦！';
      this.time = '2020-11-20';
      this.tagList = [
        {
          id: 1,
          name: '糖尿病领域'
        },
        {
          id: 2,
          name: '格华止',
        }
      ]
    },

    // 点击收藏-取消
    changeFollow() {
      let params = {
        "dataId": this.productDetail && this.productDetail.id,//数据ID 
        "type": 5,//类型 1产品 2活动 3资讯 4 临床视角
      };
      indexApi.followOrCanFollow(params).then((res)=>{
            this.isClocActive = !this.isClocActive;
            this.clocText = this.isClocActive ?  '取消' : '收藏';
            //this.getProductDynamicDetail();
        },(err)=>{
            this.$message.error(err && err.message || '操作失败，请联系管理员！')
        })
    },
    // 点赞-取消
    changeLike() {
      let params = {
        "dataId": this.productDetail.id,//数据ID 
        "type": 5,//类型 1产品 2活动 3资讯 4 临床视角
      };
      indexApi.setUserLikes(params).then((res)=>{
          this.isLike = !this.isLike;
          if(this.isLike){
              this.likesNum = this.likesNum += 1;
          }else{
              this.likesNum = this.likesNum -= 1;
          }
      },(err)=>{
        this.$message.error(err && err.message || '操作失败，请联系管理员！')
      })
    },

    // 获取产品详情
    getProductDetail() {
      let params = {
        id: this.detailID
      };

      let url = '/product/detail/' + this.detailID
      // debugger
      indexApi.getProductDetail(params, url).then((res)=>{
        // debugger
        if(res && res.code === 1000){
          let data = res && res.data && res.data;
          this.productDetail = data;
          this.isClocActive =  this.productDetail.followStatus == 1 ? false : true;
          this.clocText = this.productDetail.followStatus == 1 ? '收藏' : '取消';

          this.productDetail.title = this.productDetail && this.productDetail.name;

          this.isLike = data.likesStatus  == 1 ? false : true;
          this.likesNum = data.likesNum;

    
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

    // 获取产品动态详情
    getProductDynamicDetail() {
      let params = {
        // phone: this.form.phone
        id: this.detailID
      };

      if(this.IgnoreSecurity){
          params.IgnoreSecurity = this.IgnoreSecurity;
      }
      let url = '/productDynamic/detail/' + this.detailID
      // debugger
      indexApi.getProductDynamicDetail(params, url).then((res)=>{
        // debugger
        if(res && res.code === 1000){
          let data = res && res.data && res.data;
          this.productDetail = data;

          this.isClocActive =  this.productDetail.followStatus == 1 ? false : true;
          this.clocText = this.productDetail.followStatus == 1 ? '收藏' : '取消';

          this.isLike = data.likesStatus  == 1 ? false : true;
          this.likesNum = data.likesNum;
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

    goBack() {
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped lang="scss">
.news-container {
  background-image: url("~./images/bg.png");
  background-size: cover;
  line-height: 1;
}


.scroll-container {
    width:100%;
    max-width: 100%;
}
.main-container {
    display: flex;
    align-items: flex-start;
}

.left {
  flex: auto;
  border-radius: .22rem;
  margin-top: .6rem;
  position: relative;

  .title-wrapper {
    background: url("~./images/detail-bg.png") right/ 100% auto no-repeat;
    color: #fff;
    margin-bottom: -0.2rem;
    border-top-right-radius: .2rem;
    border-top-left-radius: .2rem;
    padding: .5rem;
    position: relative;
    // &.bg2{
    //   background: url("~./images/detail-bg2.png") right top / 5rem auto no-repeat #E61E50;;
    // }
    // &.bg3{
    //   background: url("~./images/detail-bg3.png") right top / 100% 100% no-repeat #0F69AF;;
    // }
    // &.bg4{
    //   background: url("~./images/detail-bg4.png") right top / 100% 100% no-repeat #2DBECD;;
    // }
    .title {
      margin-bottom: .2rem;
      font-size: .3rem;
      max-width: 100%;
      padding-right: .8rem;
      box-sizing: border-box;
      line-height: .35rem;
    }
    .t-c {
      font-size: .14rem;
      .save{
        padding-left: .26rem;
        margin-right: .2rem;
        cursor: pointer;
        background: url("~./images/star-detail.png") left center /.16rem .16rem no-repeat;
        &.active{
          background: url("~./images/star-active-detail.png") left center /.16rem .16rem no-repeat;
        }
      }
      .time {
        padding-left: .26rem;
        margin-right: .2rem;
        background: url("~./images/time.png") no-repeat left /.16rem .16rem;
      }
      .like {
        cursor: pointer;
        padding-left: .26rem;
        margin-right: .2rem;
        background: url("~./images/z-normal.png") no-repeat left center/.16rem .16rem;
        &.active{
          background: url("~./images/z-click.png") left center /.16rem .16rem no-repeat;
        }
      }
      .read {
        padding-left: .26rem;
        margin-right: .2rem;
        background: url("~./images/yd.png") no-repeat left center/.16rem .12rem;
      }
      .tag {
        background: rgba(255,255,255, .1);
        height: .22rem;
        font-size: .14rem;
        padding: 0 .1rem;
        line-height: .22rem;
        margin-right: .1rem;
        border-radius: 2px;
      }
    }
    .back {
      width: .52rem;
      height: .52rem;
      background: url("~./images/back.png") center center / .22rem .20rem no-repeat #eb3c96;
      position: absolute;
      right: .5rem;
      top: .4rem;
      border-radius: 100%;
      cursor: pointer;
    }
  }
  .content-wrapper {
    background: #fff;
    border-radius: .2rem;
    min-height: 5.8rem;
    position: relative;
    z-index: 2;
    padding: .2rem .5rem;
    line-height: .34rem;
    color: #333;
    font-size: .16rem;
    // overflow: auto;
    overflow-x: hidden;
    // overflow-y: scroll;
    ::v-deep video{
        width:80%;
        margin:.1rem auto 0;
    }
    .section-title {
      display: inline-block;
      border-radius: 2px;
      border: solid 1px #2dbecd;
      padding-right: .2rem;
      color: #2dbecd;
      margin: .2rem 0;
      .num {
        width: .42rem;
        text-align: center;
        display: inline-block;
        color: #fff;
        background: url("~./images/title.png") center center / 100% 100% no-repeat;
      }
    }
    .audit-no{
        color:#666;
        padding-top:0.1rem;
    }
  }

}
.right {
  flex: none;
  margin-top: .6rem;
  width: 4.5rem;
  margin-left: .1rem;
  position: sticky;
  top: 0;
}

</style>
